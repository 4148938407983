import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  newsEvents: `/news/news_events`, // News & Events
  newsMenu: `/news/menu_list`, // 新闻类别
  newsList: `/news/lists`, // 新闻列表
  newsDetail: `/news/detail` // 新闻详情
}

// News & Events
export const getNewsEventsApi = () => fetch(`${apiUrl.newsEvents}/${lang}/${site}`)
// 新闻类别
export const getNewsMenuApi = () => fetch(`${apiUrl.newsMenu}/${lang}/${site}`)
// 新闻列表
export const getNewsListApi = (params) => fetch(`${apiUrl.newsList}/${lang}/${site}`, params)
// 新闻详情
export const getNewsDetailApi = (params) => fetch(`${apiUrl.newsDetail}/${lang}/${site}`, params)

