<template>
  <div class="news-events-wrap">
    <public-banner :banner-info="bannerInfo" />
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="newsEventsTitle" site="left" />
    <div class="news-events container">
      <div class="content-box news-box">
        <h4 class="title">{{ LP.lang_news }}</h4>
        <router-link
          v-for="(item, index) in newsEventsData.newsInfo"
          :key="index"
          :to="`/${$route.params.site}/${$route.params.lang}/newsDetail?id=${item.id}`"
          class="items"
        >
          <div class="img-bg">
            <div class="img-box" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
          </div>
          <div class="text-box">
            <h4 class="name">{{ item.name }}</h4>
            <h5 class="desc" v-html="item.description"></h5>
          </div>
        </router-link>
        <a class="see-more" @click="handleMore">
          <span class="more-name">{{ LP.lang_learn_more }}</span>
          <i class="iconfont icon-a-youjiantou1x"></i>
        </a>
      </div>
      <div class="content-box events-box">
        <h4 class="title">{{ LP.lang_events }}</h4>
        <router-link v-for="(item, index) in newsEventsData.eventsInfo" :key="index" to="" class="items">
          <div class="img-bg">
            <div class="img-box" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
          </div>
          <div class="text-box">
            <h4 class="name">{{ item.title }}</h4>
            <h5 class="desc" v-html="item.description"></h5>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getNewsEventsApi } from '@/api/newsEvents'
export default {
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {},
      newsEventsTitle: {
        title: ''
      },
      newsEventsData: {}
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    if (this.$route.fullPath.includes('business')) {
      // bnaner
      await this.$store.dispatch('common/getBanner', { banner_id: 372 })
      this.bannerInfo = this.banner[0]
      // 面包屑
      await this.$store.dispatch('common/getNavigator', { type: 'news', id: 184, level: 1 })
    }
  },
  mounted() {
    this.getNewsEventsData()
  },
  methods: {
    // 获取News & Events接口
    getNewsEventsData() {
      getNewsEventsApi().then(res => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.newsEventsData = res.data
        this.newsEventsTitle.title = res.data.nav_name
      })
    },
    // 查看更多新闻
    handleMore() {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/newsList`
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.news-events-wrap {
  /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 8px 0 16px 0;
      .title {
        font-size: 24px;
      }
    }
  }
  .news-events {
    padding: 70px 0 90px 0;
    display: flex;
    .news-box {
      width: 66%;
      .items {
        display: flex;
        background: #f5f5f5;
        border-radius: 10px;
        & + .items {
          margin-top: 24px;
        }
        &:hover {
          .img-bg {
            .img-box {
              .scale();
            }
          }
          .text-box {
            .name {
              color: @theme;
            }
          }
        }
        .img-bg {
          width: 50%;
          height: 280px;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
          .img-box {
            width: 100%;
            height: 100%;
            .transition();
          }
        }
        .text-box {
          width: 50%;
          padding: 24px 16px;
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #232931;
            line-height: 27px;
            height: 54px;
            overflow: hidden;
            .transition();
          }
          .desc {
            font-size: 14px;
            color: #606266;
            line-height: 21px;
            margin-top: 16px;
            max-height: 88px;
            overflow: hidden;
          }
        }
      }
      .see-more {
        font-size: 14px;
        color: #606266;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;
        .transition();
        &:hover {
          color: @theme;
        }
        .more-name {
          line-height: 19px;
        }
        .iconfont {
          line-height: 14px;
          margin-left: 16px;
        }
      }
    }
    .events-box {
      width: calc(34% - 18px);
      margin-left: 18px;
      .items {
        display: block;
        background: #f5f5f5;
        cursor: auto;
        border-radius: 10px;
        & + .items {
          margin-top: 24px;
        }
        &:hover {
          .img-bg {
            .img-box {
              .scale();
            }
          }
        }
        .img-bg {
          height: 140px;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          .img-box {
            height: 100%;
            .transition();
          }
        }
        .text-box {
          padding: 20px 16px;
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #232931;
            line-height: 19px;
            .transition();
          }
          .desc {
            font-size: 14px;
            color: #232931;
            line-height: 21px;
            margin-top: 18px;
            max-height: 63px;
            overflow: hidden;
          }
        }
      }
    }
    .content-box {
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #232931;
        line-height: 36px;
        margin-bottom: 24px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .news-events {
      flex-wrap: wrap;
      padding: 24px 0 40px 0;
      .content-box {
        width: 100%;
        .title {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 16px;
        }
      }
      .news-box {
        .items {
          & + .items {
            margin-top: 16px;
          }
          .img-bg {
            height: 220px;
          }
          .text-box {
            .name {
              font-size: 16px;
              line-height: 24px;
              height: 50px;
            }
            .desc {
              margin-top: 12px;
            }
          }
        }
      }
      .events-box {
        margin-left: 0;
        .items {
          & + .items {
            margin-top: 16px;
          }
          .text-box {
            .name {
              font-size: 16px;
              line-height: 19px;
            }
            .desc {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
