import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  supportInfo: `/support/index`, // 语言
  downloadCenter: `/support/download_center`, // download
  suportTools: `/support/tools`, // tool
  servicePolicy: `/support/service_policy`, // service policy
  singlePage: `/support/service_policy_detail` // 隐私政策单页
}

// 语言
export const getSupportInfo = () => fetch(`${apiUrl.supportInfo}/${lang}/${site}`)
// download
export const getDownloadCenter = (params) => fetch(`${apiUrl.downloadCenter}/${lang}/${site}` + `?menu_id=${params.menu_id}&page=${params.page}&limit=${params.limit}`)
// tool
export const getSuportTools = () => fetch(`${apiUrl.suportTools}/${lang}/${site}`)
// service policy
export const getServicePolicy = () => fetch(`${apiUrl.servicePolicy}/${lang}/${site}`)
// 隐私政策单页
export const getSinglePage = (id) => fetch(`${apiUrl.singlePage}/${lang}/${site}` + `?id=${id}`)

