<template>
  <div class="news-events-wrap">
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="newsEventsData" site="left" />
    <div v-if="menuList && menuList.length" class="news-events container">
      <public-tabs :data-info="menuList" :default-index="defaultIndex" @handleTab="handleTabs" />
      <div
        v-loading="loading"
        :element-loading-text="LP.lang_loading"
        element-loading-spinner="el-icon-loading"
        class="content-box news-box"
      >
        <router-link
          v-for="(item, index) in newsList"
          :key="index"
          :to="`/${$route.params.site}/${$route.params.lang}/newsDetail?id=${item.id}`"
          class="items"
        >
          <div class="img-bg">
            <div class="img-box" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
          </div>
          <div class="text-box">
            <h4 class="name ellipsis">{{ item.name }}</h4>
            <h5 class="desc" v-html="item.description"></h5>
          </div>
        </router-link>
      </div>
      <div v-if="dataInfo.count > 10" class="public-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="dataInfo.count"
          :page-size="dataInfo.limit"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import PublicTabs from '@/components/public/PublicTabs.vue'
import { getNewsMenuApi, getNewsListApi } from '@/api/newsEvents.js'
export default {
  components: {
    PublicBreadCrumb,
    PublicTitle,
    PublicTabs
  },
  data() {
    return {
      bannerInfo: {},
      newsEventsData: {
        title: ''
      },
      menuList: [], // 新闻类别
      newsList: [], // 新闻列表
      defaultIndex: 0, // 当前选中tab
      dataInfo: {}, // 总数据
      currentPage: 1,
      loading: false
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    if (this.$route.fullPath.includes('business')) {
      // 面包屑
      await this.$store.dispatch('common/getNavigator', { type: 'news', id: 184, level: 1 })
    }
  },
  async mounted() {
    await this.getNewsMenuData()
    await this.getNewsListData(this.menuList[0].menu_id)
  },
  methods: {
    // 获取新闻类别接口
    async getNewsMenuData() {
      await getNewsMenuApi().then(res => {
        this.menuList = res.data.menu_list.map(item => {
          return {
            ...item,
            title: item.menu_name
          }
        })
        this.newsEventsData.title = res.data.nav_name
      })
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/newsList`,
        query: {
          menu_id: this.menuList[0].menu_id
        }
      })
    },
    // 获取新闻列表接口
    async getNewsListData(menu_id, page = 1, limit = 10) {
      this.loading = true
      await getNewsListApi({
        menu_id: menu_id,
        page: page,
        limit: limit
      }).then(res => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.loading = false
        this.dataInfo = res.data
        this.newsList = res.data.list
      })
    },
    // 类别切换
    handleTabs(item, index) {
      this.defaultIndex = index
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/newsList`,
        query: {
          menu_id: item.menu_id
        }
      })
      this.getNewsListData(item.menu_id)
      this.currentPage = 1
    },
    // 分页切换
    handleCurrentChange(val) {
      this.getNewsListData(this.menuList[0].menu_id, val)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.news-events-wrap {
  margin-top: 80px;
  /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 8px 0 16px 0;
      .title {
        font-size: 24px;
      }
    }
  }
  .news-events {
    .news-box {
      width: 100%;
      .items {
        display: flex;
        background: #f5f5f5;
        border-radius: 10px;
        margin-bottom: 24px;
        &:hover {
          .img-bg {
            .img-box {
              .scale();
            }
          }
          .text-box {
            .name {
              color: @theme;
            }
          }
        }
        .img-bg {
          width: 300px;
          height: 200px;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
          .img-box {
            width: 100%;
            height: 100%;
            .transition();
          }
        }
        .text-box {
          width: calc(100% - 300px);
          padding: 24px 16px;
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #232931;
            line-height: 27px;
            .transition();
          }
          .desc {
            font-size: 14px;
            color: #606266;
            line-height: 21px;
            margin-top: 16px;
            max-height: 88px;
            overflow: hidden;
          }
        }
      }
    }
    /deep/ .public-tabs {
      margin: 45px 0 16px 0;
      .tabs-list {
        border-top: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 122px;
    .news-events {
      .news-box {
        .items {
          & + .items {
            margin-top: 16px;
          }
          .img-bg {
            width: 50%;
            height: 180px;
          }
          .text-box {
            width: 50%;
            .name {
              font-size: 16px;
            }
            .desc {
              font-size: 12px;
            }
          }
        }
      }
      /deep/ .public-tabs {
        margin: 0 0 16px 0;
        .tabs-list {
          border-top: none;
        }
      }
    }
  }
}
</style>
