<template>
  <div class="news-detail">
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="newsDetailData" site="left" />
    <div v-if="Object.keys(dataInfo).length" class="detail-content container">
      <h4 class="news-title" v-html="dataInfo.name"></h4>
      <div class="news-info">
        <a class="text-box">
          <i class="iconfont icon-shizhong"></i>
          <span>{{ dataInfo.post_date }}</span>
        </a>
        <a class="text-box">
          <i class="iconfont icon-yanjing"></i>
          <span>{{ dataInfo.hits }}</span>
        </a>
      </div>
      <div class="desc-box" v-html="dataInfo.content"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getNewsDetailApi } from '@/api/newsEvents'
export default {
  components: {
    PublicBreadCrumb,
    PublicTitle
  },
  data() {
    return {
      newsDetailData: {
        title: ''
      },
      dataInfo: {}
    }
  },
  computed: {
    ...mapState({
      navigator: state => state.common.navigator
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    if (this.$route.fullPath.includes('business')) {
      // 面包屑
      await this.$store.dispatch('common/getNavigator', { type: 'news', id: 184, level: 1 })
    }
  },
  mounted() {
    this.getNewsDetailData()
  },
  methods: {
    // 获取新闻详情页接口
    getNewsDetailData() {
      getNewsDetailApi({
        id: this.$route.query.id
      }).then(res => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.dataInfo = res.data.detail
        this.newsDetailData.title = res.data.nav_name
      })
    }
  }
}
</script>

<style lang="less" scoped>
.news-detail {
  margin-top: 80px;
  padding-bottom: 92px;
  border-top: 1px solid #ddd;
  /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 8px 0 16px 0;
      .title {
        font-size: 24px;
      }
    }
  }
  .detail-content {
    margin-top: 40px;
    font-size: 14px;
    line-height: 22px;
    .news-title {
      font-size: 24px;
      font-weight: 600;
      color: #232931;
      line-height: 32px;
      text-align: center;
    }
    .news-info {
      margin: 16px 0 36px 0;
      text-align: center;
      .text-box {
        font-size: 14px;
        color: #606266;
        line-height: 19px;
        & + .text-box {
          margin-left: 32px;
        }
        .iconfont {
          margin-right: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    margin-top: 92px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 40px;
    .detail-content {
      margin-top: 16px;
      .news-title {
        font-size: 18px;
        line-height: 26px;
      }
      .news-info {
        margin: 12px 0 24px 0;
      }
    }
  }
}
</style>
