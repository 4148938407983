<template>
  <div id="single-page-wrap">
    <div class="page-box container" v-html="info"></div>
  </div>
</template>

<script>
import { getSinglePage } from '@/api/support'
export default {
  data() {
    return {
      info: ''
    }
  },
  watch: {
    '$route'(val, oldVal) {
      if (val.params.id !== oldVal.params.id) {
        this.getInfo(val.params.id)
      }
    }
  },
  created() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    this.getInfo(this.$route.params.id)
  },
  methods: {
    getInfo(id) {
      getSinglePage(id).then(res => {
        this.info = res.data.service_policy
        this.$store.commit('common/SET_MATE', res.data.seo)
      }).catch(err => err)
    }
  }
}
</script>

<style lang="less">
#single-page-wrap {
  padding: 120px 0 80px 0;
  background: @background;
  .page-box {
    background: #fff;
    padding: 50px;
    p {
      span {
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }
  }
  div {
    div {
      overflow-x: auto;
      table {
        width: 100% !important;
        min-width: 360px;
        margin: 16px 0;
        tbody {
          tr {
            td {
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 120px 0 40px 0;
    .page-box {
      padding: 24px 16px;
    }
  }
}
</style>
